import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigateWithPayload = (path) => {
  const navigate = useNavigate()

  return useCallback((searchType = '') => {
    navigate(`/${path}`, { state: { searchType }})
  }, [path])
}
