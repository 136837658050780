import { client } from '../../../start-up/client';
import { getQuery } from './get-query';
import { from, map } from 'rxjs';

const buildQuery = payload => ({
  query: getQuery,
  variables: { employeeId: [parseInt(payload.id)] },
})

export const get = payload => from(client.query(buildQuery(payload)))
  .pipe(map(({ data }) => data.employees[0]))