import { merge } from 'lodash'
import { BaseOptionChart } from 'components/charts'
import { useCreateChartEvents } from 'pages/dashboard/content/expected-visitors/hooks/use-create-chart-events'

const xaxisValues = (xAxisCategories) => ({
  type: 'datetime',
  categories: xAxisCategories,
  tickAmount: 10,
})

const fillValues = {
  type: 'gradient',
  gradient: {
    shade: 'light',
    type: "horizontal",
    shadeIntensity: 0.2,
    gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
    inverseColors: true,
    opacityFrom: 0.6,
    opacityTo: 0.6,
  }
}

export const useCreateOptions = (maxYValue, xAxisCategories) => {
  return merge(BaseOptionChart(), {
    stroke: {
      width: 5,
      curve: 'smooth'
    },
    plotOptions: {bar: {columnWidth: '11%', borderRadius: 4}},
    fill: fillValues,
    xaxis: xaxisValues(xAxisCategories),
    yaxis: {
      min: 0,
      max: maxYValue,
    },
    tooltip: {
      shared: false,
      intersect: true,
    },
    markers: {
      size: 8
    },
    chart: useCreateChartEvents()
  })
};
