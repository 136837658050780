import { useCallback } from "react";
import { useContext } from "pages/visitors/context";

export const useApplyFilters = () => {
  const { filters, setFiltersExpanded } = useContext();

  return useCallback(() => {
    console.log("Filters: ", filters);
    setFiltersExpanded(false);
  }, [filters]);
};
