import { combineEpics } from "redux-observable";
import { userEpics } from "../pages/employees/epics";
import { departmentEpics } from "../pages/departments/epics";
import { visitorsEpics } from "../pages/visitors/epics";
import { assetsEpics } from "../pages/assets/epics";
import { assetEpics } from '../pages/asset/epics'
import { observationEpics } from "../pages/observations/epics";
import { searchEpics } from "../pages/search/epics"
import { typesEpics } from "../features/types/epics";
import { entitiesEpics } from "../features/entities/epics";
import { employeeEpics } from '../pages/employee/epics';

export const rootEpic = combineEpics(
    userEpics,
    departmentEpics,
    visitorsEpics,
    assetEpics,
    assetsEpics,
    employeeEpics,
    observationEpics,
    searchEpics,
    typesEpics,
    entitiesEpics
)