import React from 'react'
import { Wrapper } from 'pages/login/wrapper'
import { LeftContent } from 'pages/login/left-content'
import { RightContent } from 'pages/login/right-content'
import { GetStarted } from 'pages/login/get-started'

export const Login = () => (
  <Wrapper>
    <GetStarted />
    <LeftContent />
    <RightContent />
  </Wrapper>
)
