import React from 'react'
import { Container, Grid } from '@mui/material';
import { useGetImage } from './hooks/use-get-image';

export const Image = () => (
  <Grid item xs={12} md={4}>
    <Container gutterBottom sx={containerStyle}>
      <img src={useGetImage()} alt={'poza'} style={imageStyle} />
    </Container>
  </Grid>
)

const containerStyle = {
  marginTop: '10px'
}

const imageStyle = {
  maxWidth: '250px',
  maxHeight: '300px',
  width: '100%',
  height: '100%',
  minWidth: '200px',
  minHeight: '250px',
  borderRadius: '5px'
}
