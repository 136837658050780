import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import gql from 'graphql-tag'

import { client } from "../../../start-up/client";
import { from } from "rxjs";

export const get = parameter => from(client.query({ query: getQuery, variables: { parameter } , fetchPolicy: 'no-cache' }))
    .pipe(map(response => response.data.search))
    .pipe(catchError(error => throwError(error.networkError.result)))

export const getQuery = gql`
    query search($parameter: [String!]) {
        search(searchTerms: $parameter) {
             ...on Visitor {
                __typename
                firstName
                lastName
                id
                expectedDate
                responsible {
                    firstName
                    lastName
                }
            } 
        }
    }
`