import React from 'react'
import { Box, Button } from '@mui/material'
import DescriptionIcon from '@mui/icons-material/Description'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export const Actions = () => (
  <Box sx={styleBox}>
    <Button variant={'contained'} color={'primary'} endIcon={<DescriptionIcon />}>
      Update
    </Button>
    <Button variant={'contained'} color={'error'} endIcon={<DeleteForeverIcon />}>
      Delete
    </Button>
  </Box>
)

const styleBox = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
}