import React from 'react'
import { Grid, Box } from '@mui/material';

export const Wrapper = ({ children }) => (
  <Grid item xs={12}>
    <Box sx={containerStyle}>
      {children}
    </Box>
  </Grid>
)

const containerStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}
