import { useCallback } from "react";
import { useContext } from "pages/visitors/context";

export const useOpenHandle = () => {
  const { setFiltersExpanded } = useContext();

  return useCallback(() => {
    setFiltersExpanded((foo) => !foo);
  }, []);
};
