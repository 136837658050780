import {ofType} from "redux-observable";
import { actions } from '../slices/get'
import {map, mergeMap, of} from "rxjs";
import { repository } from "../repositories";
import {catchError} from "rxjs/operators";

export const get = $action => $action
    .pipe(ofType(actions.attempt))
    .pipe(mergeMap(({ payload }) => getTypes(payload)))

const getTypes = (payload) => repository.get(payload)
    .pipe(map(actions.success))
    .pipe(catchError(error => of(actions.failure(error))))