import React from 'react'
import { Box } from '@mui/material';

export const VerticalLine = ({ index }) => index > 1 ? (
  <Box sx={verticalLine} />
) : ''

const verticalLine = {
  width: '3px',
  height: '60px',
  margin: '5px 0',
  backgroundColor: 'rgb(99,115,129)'
}
