import {useNavigate} from "react-router-dom";
import React from "react";
import {useFormik} from "formik";
import Page from "../../components/Page";
import {Container} from "@mui/material";
import {FormikProvider} from "formik";
import {Form} from "formik";
import {Stack} from "@mui/material";
import {Typography} from "@mui/material";
import {TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {actions} from "../../features/types/slices/create"

export const AddCategory = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: () => {
            const values = formik?.values
            const input = {...values, type: { name: values.type}} 
            dispatch(actions.attempt(input))
            navigate('/search')
        }
    });

    const { isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Page title='Gatha | Visitor'>
            <Container maxWidth={'md'}>
                <Typography variant='h2' sx={{ paddingBottom: "50px", paddingLeft: "150px"}}>Add a category</Typography>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                               Name*
                            </Typography>
                            <TextField
                                sx={{width: '70%'}}
                                label="Name"
                                {...getFieldProps('name')}
                            />
                        </Stack>
                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Add category 
                        </LoadingButton>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    )
}
