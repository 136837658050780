import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import React from "react";
import {useFormik} from "formik";
import Page from "../../components/Page";
import {Checkbox, Container} from "@mui/material";
import {FormikProvider} from "formik";
import {Form} from "formik";
import {Stack} from "@mui/material";
import {Typography} from "@mui/material";
import {TextField} from "@mui/material";
import {Select} from "@mui/material";
import {OutlinedInput} from "@mui/material";
import {Chip} from "@mui/material";
import {MenuItem} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {actions} from "../assets/slices/create"
import {useFormatTypes} from "../../features/types/hooks/useFormatTypes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const AddAsset = () => {
    const types = useFormatTypes()
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const VisitorSchema = Yup.object().shape({
        description: Yup.string().required('Description is required'),
        name: Yup.string().required('Name is required'),
    });
    const [assetTypes, setAssetTypes] = React.useState('Select asset type');

    const formik = useFormik({
        initialValues: {
            description: '',
            name: '',
            picture: '',
            serialNumber: '',
            observation: '',
            type: '',
            allowancePermitted: '',
        },
        validationSchema: VisitorSchema,
        onSubmit: () => {
            const values = formik?.values
            const input = {...values, type: { name: values.type}} 
            dispatch(actions.attempt(input))
            navigate('/search')
        }
    });

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setAssetTypes(value);
    };

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Page title='Gatha | Visitor'>
            <Container maxWidth={'md'}>
                <Typography variant='h2' sx={{ paddingBottom: "50px", paddingLeft: "150px"}}>Add an asset</Typography>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                               Description*
                            </Typography>
                            <TextField
                                sx={{width: '70%'}}
                                label="Description"
                                {...getFieldProps('description')}
                                error={Boolean(touched.description && errors.description)}
                                helperText={touched.description && errors.description}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                                Name*
                            </Typography>
                            <TextField
                                sx={{width: '70%'}}
                                label="Name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                                Serial Number
                            </Typography>
                            <TextField
                                sx={{width: '70%'}}
                                label="Serial number"
                                {...getFieldProps('serialNumber')}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                               Picture 
                            </Typography>
                            <TextField
                                sx={{width: '70%'}}
                                label="Picture"
                                {...getFieldProps('picture')}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                               AllowancePermitted 
                            </Typography>
                            <Checkbox
                                {...getFieldProps('allowancePermitted')}
                            />
                        </Stack>

                        <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                               Type 
                            </Typography>
                            <Select
                                sx={{width: '70%'}}
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                value={assetTypes}
                                onChange={handleChange}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Chip key={selected} label={selected} />
                                )}
                                MenuProps={MenuProps}
                                {...getFieldProps('type')}
                            >
                                {types.map((type) => (
                                    <MenuItem
                                        key={type}
                                        value={type}
                                    >
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>

                        <Stack direction={'column'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
                            <Typography variant='h5' sx={{alignSelf: 'left', paddingLeft: '10px'}}>
                                Observations:
                            </Typography>
                            <TextField
                                sx={{width: '100%'}}
                                fullWidth
                                multiline
                            />
                        </Stack>

                        <LoadingButton
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Add asset 
                        </LoadingButton>
                    </Form>
                </FormikProvider>
            </Container>
        </Page>
    )
}
