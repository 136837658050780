import {from, map, throwError} from "rxjs";
import {client} from "../../../start-up/client";
import gql from "graphql-tag";
import {catchError} from "rxjs/operators";

export const get = (parameter) => from(client.query({ query: getQuery, variables: { parameter }, fetchPolicy: 'no-cache'}))
    .pipe(map(response => response.data.entities))
    .pipe(catchError(error => throwError(error.networkError.result)))

const getQuery = gql`
    query entities {
        entities
        } 
`