import React from 'react'
import { Grid } from '@mui/material';
import { Header } from './header';
import { AssetsDistribution } from './assets-distribution'
import { ExpectedVisitors } from 'pages/dashboard/content/expected-visitors'
import { Visitors } from 'pages/dashboard/content/visitors'

export const Content = () =>  <Grid container spacing={3}>
  <Header />
  <Visitors />
  <AssetsDistribution />
  <ExpectedVisitors />
</Grid>