import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import { Box, InputAdornment, OutlinedInput, Toolbar } from "@mui/material";
import React from "react";
import { Sort } from "../Sort";

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  height: "73px",
  "&.Mui-focused": { boxShadow: theme.customShadows.primary },
  "& fieldset": {
    borderRadius: 0,
  },
}));

// ----------------------------------------------------------------------

export const Search = ({
  filter,
  onFilter,
  options,
  onSort,
  value,
  sortEnabled,
  customStyle,
}) => (
  <Toolbar sx={toolbarStyle(customStyle)}>
    {sortEnabled && <Sort options={options} onSort={onSort} value={value} />}
    <SearchStyle
      value={filter}
      onChange={onFilter}
      placeholder="Search | Scan or Type"
      startAdornment={
        <InputAdornment position="start">
          <Box
            component={Icon}
            icon={searchFill}
            sx={{ color: "text.disabled" }}
          />
        </InputAdornment>
      }
    />
  </Toolbar>
);

Search.defaultProps = { customStyle: "" };

Search.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

const toolbarStyle = (style) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: "0 0 0 0 !important",
  ...style,
});
