import {useSelector} from "react-redux";

export const useFormatAssets = () => {
    const assets = useSelector(selector)
    
    return assets.map(asset => ({
        ...asset,
        type: asset.type.name
    }))
}

const selector = ({ assetsReducer }) => assetsReducer.get.assets;