import React from 'react'
import { Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const MarkLeave = () => (
  <Typography variant="h6" gutterBottom sx={{marginTop: '10px'}}>
    <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant="contained"
    >
      Mark presented on leave
    </LoadingButton>
  </Typography>
)