import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, Grid } from '@mui/material';
import { useCreateOptions } from 'pages/dashboard/content/expected-visitors/hooks/use-create-options'

const xAxisCategories = [
  '08/08/2022',
  '08/09/2022',
  '08/10/2022',
  '08/11/2022',
  '08/12/2022',
  '08/13/2022',
  '08/14/2022',
  '08/15/2022',
  '08/16/2022',
  '08/17/2022',
  '08/18/2022',
  '08/19/2022',
  '08/20/2022',
  '08/21/2022',
]

const CHART_DATA = [
  {
    name: 'Expected',
    type: 'area',
    data: [175, 121, 123, 90, 5, 0, 0, 68, 192, 130, 25, 12, 0, 0]
  },
];

export const ExpectedVisitors = () => (
  <Grid item xs={12}>
    <Card>
      <CardHeader title="Expected visitors by days"/>
      <Box sx={{p: 3, pb: 1}} dir="ltr">
        <ReactApexChart type="area" series={CHART_DATA} options={useCreateOptions(200, xAxisCategories)} height={364}/>
      </Box>
    </Card>
  </Grid>
)