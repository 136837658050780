import PropTypes from 'prop-types';
// material
import { MenuItem, TextField } from '@mui/material';
import {withStyles} from "@mui/styles";

export const Sort = ({ options, onSort, value }) => (
        <CssTextField select size="small" value={value} onChange={onSort}>
          {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
          ))}
        </CssTextField>
    )

Sort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func
};

const CssTextField = withStyles({
    root: {
        flexDirection: 'unset',
        width: '120px',
        '& .MuiOutlinedInput-root': {
                borderRadius: '0',
                height: '73px',
                width: '100%',
        },
    },
})(TextField);

