import React from "react";
import { AccordionActions, Button } from "@mui/material";
import { useApplyFilters } from "pages/visitors/accordion/hooks/use-apply-filtes";

export const Actions = () => (
  <AccordionActions>
    <Button variant={"contained"} onClick={useApplyFilters()}>
      Apply filters
    </Button>
  </AccordionActions>
);
