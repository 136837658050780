import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { CardHeader } from '@mui/material';
import { ChartWrapperStyle } from './style';
import { useCreateOptions } from './hooks/use-create-options';
import { Wrapper } from './wrapper';

const CHART_DATA = [97, 32, 134, 105, 409];

const labels = ['Laptop', 'Cars', 'Toolbox', 'Small Electronics', 'Others'];

export const AssetsDistribution = () => (
  <Wrapper>
    <CardHeader title="Assets distribution"/>
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart type="pie" series={CHART_DATA} options={useCreateOptions(labels)} height={280}/>
    </ChartWrapperStyle>
  </Wrapper>  
)
