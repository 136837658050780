import React from "react";
import { Card } from "@mui/material";
import { Table as MuiTable } from "components/Table";
import { useContext } from "pages/visitors/context";
import { useFetchVisitors } from "pages/visitors/hooks/useFetchVisitors";
import { useCreateTableData } from "pages/visitors/hooks/use-create-table-data";

const headColumns = [
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "responsible", label: "Responsible" },
  { id: "expectedDate", label: "Expected Date" },
];

export const Table = () => {
  const { searchValue } = useContext();
  const data = useCreateTableData();
  useFetchVisitors(searchValue);

  return (
    <Card>
      <MuiTable headColumns={headColumns} data={data} clickable />
    </Card>
  );
};
