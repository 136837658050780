import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import searchFill from "@iconify/icons-eva/search-fill";
import personOutline from "@iconify/icons-eva/person-outline";
import monitorOutline from "@iconify/icons-eva/monitor-outline";
import peopleOutline from "@iconify/icons-eva/people-outline";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'search',
    path: '/search',
    icon: getIcon(searchFill)
  },
  {
    title: 'status',
    path: '/status',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'assets',
    path: '/assets',
    icon: getIcon(monitorOutline)
  },
  {
    title: 'employees',
    path: '/employees',
    icon: getIcon(personOutline)
  },
  {
    title: 'visitors',
    path: '/visitors',
    icon: getIcon(peopleOutline)
  },
  {
    title: 'settings',
    path: '/settings',
    icon: getIcon(settings2Fill)
  }
];

export default sidebarConfig;
