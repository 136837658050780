import React, { useContext as useReactContext, useState } from 'react'
import { useCreateFormValues } from 'pages/login/hooks/use-create-form-values'

export const Context = React.createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultValues = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const formik = useCreateFormValues()

  return {
    showPassword,
    setShowPassword,
    selectValue,
    setSelectValue,
    ...formik
  }
}
