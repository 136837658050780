import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import { Login } from 'pages/login';
import Register from './pages/Register';
import { Dashboard } from 'pages/dashboard';
import NotFound from './pages/Page404';
import { Search } from 'pages/search';
import Asset from "./pages/asset";
import Employees from "./pages/employees";
import Status from "./pages/status";
import Visitor from "./pages/visitor";
import { Visitors } from "pages/visitors";
import { Employee } from 'pages/employee';
import Assets from "./pages/assets";
import { EmployeeHistory } from 'pages/employee-history';
import UnexpectedVisitor from "./pages/unexpectedVisitor";
import ExpectedVisitor from "./pages/expectedVisitor";
import {SettingsPage} from "pages/settings";
import AddAsset from "./pages/addAsset";
import { AddEmployee } from 'pages/add-employee';
import {AddCategory} from "pages/addCategory";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/search" replace /> },
        { path: 'status', element: <Dashboard /> },
        { path: 'search', element: <Search /> },
        { path: 'assets', element: <Assets /> },
        { path: 'asset/:id', element: <Asset /> },
        { path: 'employees', element: <Employees /> },
        { path: 'employee/:id', element: <Employee /> },
        { path: 'employee/history/:id', element: <EmployeeHistory /> },
        { path: 'history', element: <Status /> },
        { path: 'visitors', element: <Visitors /> },
        { path: 'unexpected-visitor', element: <UnexpectedVisitor/> },
        { path: 'expected-visitor', element: <ExpectedVisitor />},
        { path: 'visitor/:id', element: <Visitor /> },
        { path: 'settings', element: <SettingsPage />},
        { path: 'add-asset', element: <AddAsset/>},
        {path: 'add-category', element: <AddCategory/>},
        { path: 'add-employee', element: <AddEmployee />}
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
