import React from 'react'
import { Container, Grid } from '@mui/material';
import Page from '../../components/Page';

export const Wrapper = ({ children }) => (
  <Page>
    <Container maxWidth={'md'}>
      <Grid container>
        {children}
      </Grid>
    </Container>
  </Page>
)