import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { actions } from "../slices/get";

export const useFetchVisitors = (searchValue) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.attempt(["Visitor", searchValue]));
  }, [searchValue]);
};
