import { useSelector } from 'react-redux'

export const useCreateTableData = () => {
  const visitors = useSelector(selector)

  return visitors.map(visitor => ({
    ...visitor,
    responsible: `${visitor.responsible?.firstName} ${visitor.responsible?.lastName}`,
    expectedDate: new Date(visitor.expectedDate * 1000).toLocaleString()
  }))
}

const selector = ({ visitorsReducer }) => visitorsReducer.get.visitors;
