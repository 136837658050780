import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useGetName } from './hooks/use-get-name';

export const Title = () => <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
  <Typography variant={'h2'} sx={titleStyle} textAlign={'start'}>Employee</Typography>
  <Typography variant={'h4'} sx={titleStyle} textAlign={'start'}>{useGetName()}</Typography>
</Grid>

const titleStyle = {
  marginBottom: '25px'
}