import React, { useContext as useReactContext, useState } from "react";

export const Context = React.createContext({});

export const useContext = () => useReactContext(Context);

export const useDefaultValues = () => {
  const [searchValue, setSearchValue] = useState("");
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  const [filters, setFilters] = useState({ type: "expected" });

  return {
    searchValue,
    setSearchValue,
    filtersExpanded,
    setFiltersExpanded,
    filters,
    setFilters,
  };
};
