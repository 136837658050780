import React from 'react'
import { Grid } from '@mui/material';
import { MarkReturn } from './mark-return';
import { MarkLeave } from './mark-leave';

export const Buttons = () => (
  <Grid item xs={12} sx={{ marginTop: '25px' }}>
    <MarkReturn />
    <MarkLeave />
  </Grid>
)