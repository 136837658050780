import { useCallback } from 'react'
import { useContext } from 'pages/search/context'

export const useOnSort = () => {
  const { setFirstSearch, setSearchValue } = useContext()

  return useCallback(e => {
    setFirstSearch(false)
    setSearchValue(e.target.value)
  }, [])
}