import { from, map } from 'rxjs';
import { client } from '../../../start-up/client';
import { getQuery } from './get-query';

const buildQuery = payload => ({
  query: getQuery,
  variables: {assetId: [parseInt(payload.id)]},
})

export const get = payload => from(client.query(buildQuery(payload)))
  .pipe(map(({ data }) => data.assets[0]))
