import React from 'react'
import { Wrapper } from './wrapper';
import { Typography } from '@mui/material';
import { useGetTitle } from '../hooks/use-get-title';
import { LeftStep } from './left-step';
import { VerticalLine } from './vertical-line';
import { RightStep } from './right-step';

const mockHistory = [
  {
    index: 5,
    title: 'Jon Deva',
    date: 'now'
  },
  {
    index: 4,
    title: 'Miuta Mihai',
    date: '4 Jul'
  },
  {
    index: 3,
    title: 'Octavian Floare',
    date: '2 April'
  },
  {
    index: 2,
    title: 'Constantinescu Andrei',
    date: '1 Mar'
  },
  {
    index: 1,
    title: 'Daniel Schiau',
    date: '19 Jan'
  }
  
]

export const History = () => {
  const type = useGetTitle()
  
  return (
    <Wrapper>
      <Typography sx={typographyStyle} variant={'h3'}>{type} history</Typography>
      {
        mockHistory.map(( item) => {
          if (item.index % 2 === 0) {
            return <LeftStep key={item.index} item={item}/>
          } else {
            return <RightStep key={item.index} item={item}/>
          }
        })
      }
    </Wrapper>
  );
}

const typographyStyle = {
  marginTop: '15px',
  marginBottom: '15px'
}