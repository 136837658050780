import React from "react";
import { Accordion as MuiAccordion } from "@mui/material";
import { Summary } from "pages/visitors/accordion/summary";
import { Details } from "pages/visitors/accordion/details";
import { Actions } from "pages/visitors/accordion/actions";
import { useOpenHandle } from "pages/visitors/accordion/hooks/use-open-handle";
import { useContext } from "pages/visitors/context";

export const Accordion = () => {
  const { filtersExpanded } = useContext();
  console.log("filtersExpanded", filtersExpanded);

  return (
    <MuiAccordion
      sx={accordionStyle}
      expanded={filtersExpanded}
      onChange={useOpenHandle()}
    >
      <Summary />
      <Details />
      <Actions />
    </MuiAccordion>
  );
};

const accordionStyle = {
  margin: "5px 0",
  padding: "10px 0",
  border: "1px solid #E4EDEA",
  borderRadius: "10px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
};
