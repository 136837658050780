import React from 'react'
import { TextField } from '@mui/material'
import { useContext } from 'pages/login/context'
import { ShowPassword } from 'pages/login/right-content/form/fields/show-password'

export const Password = () => {
  const { errors, touched, getFieldProps, showPassword } = useContext()

  return (
    <TextField
      fullWidth
      autoComplete="current-password"
      type={showPassword ? 'text' : 'password'}
      label="Password"
      {...getFieldProps('password')}
      InputProps={{
        endAdornment: <ShowPassword />
      }}
      error={Boolean(touched.password && errors.password)}
      helperText={touched.password && errors.password}
      disabled
    />
  )
}