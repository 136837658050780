import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import gql from 'graphql-tag'

import { client } from "../../../start-up/client";
import { from } from "rxjs";

export const create = input =>
    from(client.mutate({mutation: createQuery, variables: {...input}, fetchPolicy: 'no-cache'}))
        .pipe(map(response => response.data))
        .pipe(catchError(error => throwError(error.networkError.result)))

export const createQuery = gql`
mutation createVisitor($firstName: String!, $lastName: String!, $email: String!, $personalNumber: String!, $phoneNumber: String!, $responsibleId: String!, $expectedDate: Timestamp!){
  createVisitor(firstName: $firstName, lastName: $lastName, email: $email, personalNumber: $personalNumber, phoneNumber: $phoneNumber, responsibleId: $responsibleId, expectedDate: $expectedDate) {
    firstName
    lastName
  }
}
`