import { useSelector } from "react-redux";
import Page from "../../components/Page";
import { Container } from "@mui/material";
import { Card } from "@mui/material";
import { Table } from "../../components/Table/index";
import React, { useState } from "react";
import { useFetchEmployees } from "./hooks/useFetchEmployees";
import { debounce } from "lodash";
import { Search } from "components/Search";

const headColumns = [
  { id: "firstName", label: "First name" },
  { id: "lastName", label: "Last name" },
  { id: "employeeId", label: "Employee id"}, 
];

export const Employee = () => {
  const employee = useSelector(selector);
  const [filterValue, setFilterValue] = useState("");

  const onFilter = (e) => setFilterValue(e.target.value);

  const debounceOnFilter = debounce(onFilter, 333);

  useFetchEmployees(filterValue);

  return (
    <Page title="Gatha | Employees">
      <Container>
        <Search onFilter={debounceOnFilter} />
        <Card>
          <Table headColumns={headColumns} data={employee} clickable />
        </Card>
      </Container>
    </Page>
  );
};

const selector = ({ userReducer }) => userReducer.get.employees;
