import { useCallback } from 'react'
import { debounce } from 'lodash'
import { useContext } from 'pages/search/context'

export const useOnFilter = () => {
  const { setFirstSearch, setFilterValue } = useContext()

  const callback = useCallback(e => {
    setFirstSearch(false)
    setFilterValue(e.target.value)
  }, [])

  return debounce(callback, 333)
}