import React from 'react'
import { LoadingButton } from '@mui/lab'
import { useContext } from 'pages/login/context'

export const Submit = () => {
  const { isSubmitting } = useContext()

  return (
    <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      loading={isSubmitting}
    >
      Login
    </LoadingButton>
  )
}
