import React from 'react'
import { useContext } from '../context';
import { Card } from './card';
import { Grid } from '@mui/material';
import { Title } from './title';
import { Wrapper } from './wrapper';

export const Observations = () => {
  const { observations } = useContext()
  
  return <Wrapper>
    <Title />
    <Grid container sx={gridStyle}>
      {
        observations.map(observation => <Card key={observation.id} item={observation} />)
      }
    </Grid>
  </Wrapper>
}

const gridStyle = {
  overflow: 'auto',
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}
