import React from 'react';
import { Wrapper } from 'pages/login/right-content/form/wrapper'
import { Fields } from 'pages/login/right-content/form/fields'
import { RememberMe } from 'pages/login/right-content/form/remember-me'
import { Submit } from 'pages/login/right-content/form/submit'

export const Form = () => (
  <Wrapper>
    <Fields/>
    <RememberMe/>
    <Submit/>
  </Wrapper>
)
