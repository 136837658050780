import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLoginHandle } from 'pages/login/hooks/use-login-handle'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  password: Yup.string().required('Password is required')
});

export const useCreateFormValues = () => useFormik({
  initialValues: {
    email: '',
    password: '',
    remember: true
  },
  validationSchema: LoginSchema,
  onSubmit: useLoginHandle()
})