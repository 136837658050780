import React from 'react'
import { Stack, Typography } from '@mui/material'

export const Title = () => (
  <Stack sx={{mb: 5}}>
    <Typography variant="h4" gutterBottom>
      Sign in to Gatha
    </Typography>
    <Typography sx={{color: 'text.secondary'}}>Enter your details below.</Typography>
  </Stack>
)