import Page from "../../components/Page";
import { Card, Container } from "@mui/material";
import { Table } from "../../components/Table/index";
import React, { useState } from "react";
import { Search } from "components/Search";
import { useFetchAssets } from "./hooks/useFetchAssets";
import { useMakeSortOptions } from "./hooks/useMakeSortOptions";
import { useFormatAssets } from "./hooks/useFormatAssets";
import { debounce } from "lodash";
import { useCreateFilterType } from "./hooks/use-create-filter-type";

const headColumns = [
  { id: "type", label: "Type" },
  { id: "description", label: "Description" },
  { id: "serialNumber", label: "Serial number" },
];

export const Assets = () => {
  const assets = useFormatAssets();
  const types = useMakeSortOptions();
  const searchType = useCreateFilterType();

  const [sortValue, setSortValue] = useState(searchType);
  const [filterValue, setFilterValue] = useState("");

  const onSort = (e) => setSortValue(e.target.value);

  const onFilter = (e) => setFilterValue(e.target.value);

  const debounceOnFilter = debounce(onFilter, 333);

  useFetchAssets(sortValue, filterValue);

  return (
    <Page title="Gatha | Assets">
      <Container>
        <Search
          onFilter={debounceOnFilter}
          options={types}
          onSort={onSort}
          value={sortValue}
          sortEnabled
        />
        <Card>
          <Table headColumns={headColumns} data={assets} clickable />
        </Card>
      </Container>
    </Page>
  );
};
