import { useTheme } from '@mui/material/styles'
import { useNavigateWithPayload } from 'helpers/hooks/use-navigate-with-payload'

export const useCreateChartOptions = () => {
  const theme = useTheme()
  const navigate = useNavigateWithPayload('assets')

  return ({
    toolbar: { show: false },
    zoom: { enabled: false },
    foreColor: theme.palette.text.disabled,
    fontFamily: theme.typography.fontFamily,
    events: {
      dataPointSelection: (event, chartContext,  config) => {
        const searchType = config.w.config.labels[config.dataPointIndex]

        navigate(searchType)
      },
      dataPointMouseEnter: event => event.path[0].style.cursor = 'pointer'
    }
  })
}