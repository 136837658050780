import React from 'react'
import { Card, Grid } from '@mui/material';

export const Wrapper = ({ children }) => (
  <Grid item xs={12} md={6} lg={5}>
    <Card>
      {children}
    </Card>
  </Grid>
)
