import React from "react";
import { FormControl, FormLabel, RadioGroup } from "@mui/material";
import { useHandleRadio } from "pages/visitors/accordion/details/filters/hook/use-handle-radio";

export const Wrapper = ({ children }) => (
  <FormControl>
    <FormLabel id="visitors-filter">Visitors type</FormLabel>
    <RadioGroup
      row
      aria-labelledby="visitors-filter"
      defaultValue="expected"
      name="visitors-filter"
      onChange={useHandleRadio()}
    >
      {children}
    </RadioGroup>
  </FormControl>
);
