import React from "react";
import { Wrapper } from 'pages/visitors/wrapper'
import { Buttons } from 'pages/visitors/buttons'
import { Search } from 'pages/visitors/search'
import { Table } from 'pages/visitors/table'
import { Accordion } from 'pages/visitors/accordion'

export const Visitors = () => (
  <Wrapper>
    <Buttons />
    <Search />
    <Accordion />
    <Table />
  </Wrapper>
)