import React from 'react';
import { Wrapper } from 'pages/search/wrapper'
import { SearchBar } from 'pages/search/search-bar'
import { Table } from 'pages/search/table'

export const Search = () => (
  <Wrapper>
    <SearchBar />
    <Table />
  </Wrapper>
)

