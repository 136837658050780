import {Container, Typography} from "@mui/material";
import Page from "../../components/Page";
import {LoadingButton} from "@mui/lab";
import React from "react";
import {useNavigate} from "react-router-dom";

export const SettingsPage = () => {
    const navigate = useNavigate()
    const assetClick = () => navigate('/add-asset')
    const categoryClick = () => navigate('/add-category')
    const employeeClick = () => navigate('/add-employee')
    
    return <Page title="Gatha | Settings">
        <Container>
            <Typography variant='h1' sx={{paddingBottom: "50px"}}> Settings </Typography>
            <Typography variant="h6" gutterBottom sx={{marginTop: '10px'}}>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{marginRight: "10px", width: "150px"}}
                    onClick={employeeClick}
                >
                    Add employee
                </LoadingButton>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{marginRight: "10px", width: "150px"}}
                    onClick={assetClick}
                >
                    Add asset
                </LoadingButton>
                <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{marginRight: "10px", width: "150px"}}
                    onClick={categoryClick}
                >
                    Add category
                </LoadingButton>
            </Typography>
        </Container>
    </Page>;
}