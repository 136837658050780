import Header from "./header"
import Scrollbar from "../Scrollbar";
import {
    Card,
    Table as MuiTable,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
} from '@mui/material';
import SearchNotFound from "../SearchNotFound";
import { useState } from "react";
import { Body } from "./body";

export const Table = ({ headColumns, data, clickable, customClick=false}) => {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [filter, setFilter] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const isNotFound = data.length === 0;
    
    return (
            <Card>
                <Scrollbar>
                    <TableContainer>
                        <MuiTable>
                            <Header order={order}
                                    orderBy={orderBy}
                                    headLabel={headColumns}
                                    rowCount={data.length}
                                    onRequestSort={handleRequestSort} />
                            <Body emptyRows={emptyRows}
                                  filtered={data}
                                  page={page}
                                  rowsPerPage={rowsPerPage}
                                  bodyColumns={headColumns}
                                  clickable={clickable}
                                  customClick={customClick}
                                  />
                            {isNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filter} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </MuiTable>
                    </TableContainer>
                </Scrollbar>
        
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    sx={{paddingTop: '20px'}}
                />
            </Card>
    )
}