import React from 'react'
import { styled } from '@mui/material/styles'
import { Container } from '@mui/material'

export const Wrapper = ({ children }) => (
  <Container>
    <ContentStyle>
      {children}
    </ContentStyle>
  </Container>
)

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));
