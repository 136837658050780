import React from 'react'
import { Typography } from '@mui/material';
import { useYesOrNo } from '../../../hooks/useYesOrNo';
import { useGetCanGoOutside } from './hooks/use-get-can-go-outside';

export const LeaveWith = () => {
  const isAllowedToTakeOutside = useGetCanGoOutside()
  const yesOrNo = useYesOrNo(isAllowedToTakeOutside)
  
  return (
    <Typography variant="h5" gutterBottom sx={{marginTop: '10px'}}>
      Allowed to leave with: {yesOrNo}
    </Typography>
  );
}