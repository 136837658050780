import React from 'react'
import { styled } from '@mui/material/styles'
import Page from 'components/Page'
import { Context, useDefaultValues } from 'pages/login/context'

export const Wrapper = ({ children }) => (
  <Context.Provider value={useDefaultValues()}>
    <RootStyle title="Gatha | Login">
      {children}
    </RootStyle>
  </Context.Provider>
)

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));
