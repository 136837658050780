import React from 'react'
import { Avatar, Box, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

export const Header = ({ item }) => (
  <Box sx={containerStyle}>
    <Avatar sx={{ backgroundColor: red[500] }}>
      A
    </Avatar>
    <Box sx={{ marginLeft: '5px' }}>
      <Typography variant="h4" component="h6">
        {item.firstName}
      </Typography>
      <Typography variant="body2" component="h6">
        {item.createdDate} 
      </Typography>
    </Box>
  </Box>
) 

const containerStyle = {
  display: 'flex',
  justifyContent: '',
  alignItems: 'start',
  width: '80%',
}