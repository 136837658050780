import React from 'react'
import { AccordionSummary, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'

export const Summary = () => (
  <AccordionSummary
    expandIcon={<ExpandMore />}
    id={'filters-summary'}
  >
    <Typography>
      Filters
    </Typography>
  </AccordionSummary>
)