import { merge } from 'lodash';
import { BaseOptionChart } from 'components/charts'
import { cyan } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles';
import { useCreateChartOptions } from './use-create-chart-options'

const getColors = theme => ([
    theme.palette.primary.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
    cyan[500],
  ])

export const useCreateOptions = (labels) => {
  const theme = useTheme()

  return merge(BaseOptionChart(), {
    colors: getColors(theme),
    labels,
    stroke: {
      colors: [theme.palette.background.paper]
    },
    legend: {
      floating: true, horizontalAlign: 'center'
    },
    dataLabels: {
      enabled: true, dropShadow: {enabled: false}
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {show: false}
        }
      }
    },
    chart: useCreateChartOptions()
  })
}
