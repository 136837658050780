import { actions } from '../slices/get'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useFetchAsset = id => {
  const { attempt } = actions
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(attempt({ id }))
  }, [dispatch, attempt, id])
}