import React from "react";
import { Search } from "components/Search";
import { useOnFilter } from "pages/search/hooks/use-on-filter";
import { useOnSort } from "pages/search/hooks/use-on-sort";
import { useMakeSortOptions } from "pages/search/hooks/useMakeSortOptions";
import { useFetchSearch } from "pages/search/hooks/useFetchSearch";
import { useContext } from "pages/search/context";

export const SearchBar = () => {
  const { searchValue } = useContext();
  useFetchSearch();

  return (
    <Search
      onFilter={useOnFilter()}
      options={useMakeSortOptions()}
      onSort={useOnSort()}
      value={searchValue}
      sortEnabled
    />
  );
};
