import React from 'react'
import { AddExpected } from 'pages/visitors/buttons/add-expected'
import { AddUnexpected } from 'pages/visitors/buttons/add-unexpected'
import { Box } from '@mui/material'

export const Buttons = () => (
  <Box sx={{ margin: '10px 0' }}>
    <AddExpected/>
    <AddUnexpected/>
  </Box>
)
