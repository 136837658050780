import React from 'react'
import { Link } from '@mui/material'
import AuthLayout from 'layouts/AuthLayout'

export const GetStarted = () => (
  <AuthLayout>
    Don’t have an account? &nbsp;
    <Link href={'https://nerds.sh/contact/'} underline={'hover'}>
      Get started
    </Link>
  </AuthLayout>
)