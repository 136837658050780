import React from 'react'
import { Typography } from '@mui/material';

export const Title = () => (
  <Typography variant={'h2'} textAlign={'start'} sx={textStyle}>
    Observations 
  </Typography>
)

const textStyle = {
  marginBottom: '25px',
  letterSpacing: '.1px',
}