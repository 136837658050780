import { useCallback } from "react";
import { useContext } from "pages/visitors/context";

export const useHandleRadio = () => {
  const { setFilters } = useContext();

  return useCallback((e) => {
    setFilters((prevProp) => ({ ...prevProp, type: e.target.value }));
  }, []);
};
