import React from 'react'
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

export const MarkReturn = () => (
  <Typography variant="h6" gutterBottom sx={{marginTop: '10px'}}>
    <LoadingButton
      fullWidth
      size="large"
      type="submit"
      variant="contained"
    >
      Mark presented on return 
    </LoadingButton>
  </Typography>
)