import React from 'react'
import { IconButton, InputAdornment } from '@mui/material'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
import { useContext } from 'pages/login/context'
import { useHandleShowPassword } from 'pages/login/right-content/form/fields/hooks/use-handle-show-password'

export const ShowPassword = () => {
  const { showPassword } = useContext()

  return (
    <InputAdornment position="end">
      <IconButton onClick={useHandleShowPassword()} edge="end">
        <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
      </IconButton>
    </InputAdornment>
  )
}
