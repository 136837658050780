import React from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { teal } from '@mui/material/colors'
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { fShortenNumber } from 'utils/formatNumber'
import { useNavigateWithPayload } from 'helpers/hooks/use-navigate-with-payload'

const TOTAL = 23;

export const TodayVisitors = () => {
  const navigate = useNavigateWithPayload('visitors')

  return (
    <RootStyle onClick={() => navigate()}>
      <IconWrapperStyle>
        <AccountCircleIcon/>
      </IconWrapperStyle>
      <Typography variant="h3">{fShortenNumber(TOTAL)}</Typography>
      <Typography variant="subtitle2" sx={{opacity: 0.72}}>
        Visitors expected today
      </Typography>
    </RootStyle>
  )
}

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: teal[700],
  backgroundColor: teal[50]
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  backgroundColor: teal[100],
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.secondary.main, 0)} 0%, ${alpha(
    teal[500],
    0.24
  )} 100%)`
}));
