import {useDispatch} from "react-redux";
import {useEffect} from "react";
import * as _ from "lodash";
import { actions } from "../slices/get";

export const useFetchAssets = (sortValue, filterValue) => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        const isAll = sortValue === "all" ? '' : _.capitalize(sortValue)
        dispatch(actions.attempt(["Asset", isAll, filterValue]))
    },[sortValue, filterValue])
}