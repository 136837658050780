import { merge } from 'lodash'
import { BaseOptionChart } from 'components/charts'
import { useCreateChartEvents } from 'pages/dashboard/content/visitors/hooks/use-create-chart-events'

const xaxisValues = (xAxisCategories) => ({
  type: 'datetime',
  categories: xAxisCategories,
  tickAmount: 10,
  labels: {
    formatter: (value, timestamp, opts) => opts.dateFormatter(new Date(timestamp), 'dd MMM')
  }
})

export const useCreateOptions = (maxYValue, xAxisCategories) => merge(BaseOptionChart(), {
  stroke: {
    width: 5,
    curve: 'smooth'
  },
  plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
  xaxis: xaxisValues(xAxisCategories),
  yaxis: {
    min: 0,
    max: maxYValue,
  },
  tooltip: {
    shared: false,
    intersect: true,
  },
  markers: {
    size: 8
  },
  chart: useCreateChartEvents()
});
