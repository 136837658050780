import React from 'react'
import { useBehaviour } from './hooks/use-behaviour';
import { Container, Grid } from '@mui/material';
import Page from '../../components/Page';
import { Context, useDefaultContext } from './context';

export const Wrapper = ({ children }) => {
  useBehaviour()
  
  return  <Context.Provider value={useDefaultContext()}>
    <Page title="Gatha | Employee">
      <Container>
        <Grid container sx={gridStyle}>
          {children}
        </Grid>
      </Container>
    </Page>
  </Context.Provider>
} 

const gridStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}