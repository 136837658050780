import gql from 'graphql-tag';

export const getQuery = gql`
  query assets($assetId: [Int]) {
  assets(ids: $assetId) {
    id
    description
    name
    observations
    picture
    allowancePermitted
    serialNumber
    type{
        id
        name
    }
  }
}
`
