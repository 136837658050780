import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    attempt: null,
    entities: [],
    error: null
}

const attempt = (state, { payload }) => ({...state, attempt: payload})
const success = (state, { payload }) => ({...state, entities: payload })
const failure = (state, { payload }) => ({...state, error: payload })
const reset = () => ({ ...initialState })

export const get = createSlice({
    name: "getEntities",
    initialState,
    reducers: {
        attempt,
        success,
        failure,
        reset
    }
})

export const { actions } = get