import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { actions } from '../slices/get'
import { repositories } from '../repositories/index'

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap(execute))

const execute = ({ payload }) => repositories.get(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = payload => of(actions.failure(payload))