import Page from "../../components/Page";
import {Box, Container, Grid, TextField, Typography} from "@mui/material";

export const Visitor = () => {
    
return (
    <Page title="Gatha | Visitor">
        <Container>
            <Typography variant='h2' sx={{ paddingLeft: '40px'}}>Visitor</Typography>
            <Grid container sx={{ paddingLeft:'40px' }}>
                <Grid item xs={2}>
                    <Box alignContent='flex-start'>
                        <img src={'https://www.investopedia.com/thmb/KfGSwVyV8mOdTHFxL1T0aS3xpE8=/1148x1148/smart/filters:no_upscale()/qr-code-bc94057f452f4806af70fd34540f72ad.png'} alt={'profile picture'}/>
                        <Typography variant='h5' sx={{ paddingLeft: '20px'}}>Scan on arrival</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Container>
                        <Typography variant="h4" gutterBottom>
                            John Doe
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Phone: 0000000001 
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Email: johndoe@gmail.com
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{paddingTop: '50px'}}>
                            Responsible: <a href="/employee/3">Constantinescu Andrei</a> 
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{paddingTop: '50px'}}>
                            Observations: He is expected to come with his pet
                        </Typography>
                        <Typography variant="h6" gutterBottom sx={{paddingTop: '50px'}}>
                           Expected Date: 7/22/2022, 12:30:00 PM 
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
        </Container>
    </Page>
)
}