import {useDispatch} from "react-redux";
import {useEffect} from "react";
import * as _ from "lodash";
import { actions } from "../slices/get";
import { useContext } from 'pages/search/context'

export const useFetchSearch = () => {
    const dispatch = useDispatch()
    const { filterValue, searchValue } = useContext()

    useEffect(() => {
        const isAll = searchValue === "all" ? '' : _.capitalize(searchValue)
        dispatch(actions.attempt([isAll, filterValue]))
    }, [searchValue, filterValue])
}