import React, { useContext as useReactContext, useState } from 'react'

export const Context = React.createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultContext = () => {
  const [ observations, setObservations ] = useState([])
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  
  return { observations, setObservations, isModalOpen, setIsModalOpen }
}