import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  search: '',
  type: 'all'
}

const setType = (state, { payload }) => ({ ...state, type: payload })

const setSearch = (state, { payload }) => ({ ...state, search: payload })

export const search = createSlice({
  initialState,
  name: 'assetsSearch',
  reducers: {
    setType,
    setSearch
  }
})

export const { actions } = search