import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import gql from 'graphql-tag'

import { client } from "../../../start-up/client";
import { from } from "rxjs";

export const create = parameter => from(client.mutate({ mutation: createQuery, variables: {...parameter}, fetchPolicy: 'no-cache' }))
    .pipe(map(response => response.data))
    .pipe(catchError(error => throwError(error.networkError.result)))

export const createQuery = gql`
    mutation createAsset($allowancePermitted: Boolean!, $description: String!, $name: String!, $observations: String, $picture: String!, $serialNumber: String!, $type: TypeInput!) {
        createAsset(allowancePermitted: $allowancePermitted, description: $description, name: $name, observations: $observations, picture: $picture, serialNumber: $serialNumber, type: $type) {
                id
                serialNumber
                description
                picture
        }
    }
`