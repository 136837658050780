import { Wrapper } from './wrapper';
import { Content } from './content';
import { Title } from './title';

export const Dashboard = () => {
  return (
    <Wrapper>
      <Title />
      <Content />
    </Wrapper>
  );
}
