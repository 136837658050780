import React from 'react'
import { Button } from '@mui/material'
import { useNavigateWithPayload } from 'helpers/hooks/use-navigate-with-payload'

export const AddExpected = () => (
  <Button
    size="large"
    type="submit"
    variant="contained"
    onClick={useNavigateWithPayload('/expected-visitor')}
    sx={{marginRight: "10px"}}
  >
    Add expected visitor
  </Button>
)
