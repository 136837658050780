import React from 'react'
import { Box, Grid } from '@mui/material';

export const Wrapper = ({ children }) => (
  <Grid item xs={12} sx={foo}>
    <Box sx={boxStyle}>
      {children}
    </Box>
  </Grid>
)

const boxStyle = { 
  display: 'flex',
  width: '90%',
  height: '350px',
  alignItems: 'center',
  flexDirection: 'column',
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
  marginBottom: '50px',
  borderRadius: '25px',
  padding: '25px',
}

const foo = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}