import React from 'react';
import { Grid } from '@mui/material';
import { Information } from './information';
import { SerialNumber } from './serial-number';
import { Responsible } from './responsible';
import { LeaveWith } from './leave-with';
import { ExpectedReturn } from './expected-return';

export const Content = () => (
  <Grid container item xs={12} md={6}>
    <Information />
    <SerialNumber />
    <Responsible />
    <LeaveWith />
    <ExpectedReturn />
  </Grid>
)