import { useFormatTypes } from "features/types/hooks/useFormatTypes";

export const useMakeSortOptions = () => {
    const types = useFormatTypes()
    types.push('All')
    types.push('Inside')
    types.push('Outside')
    types.push('Overdue')
    types.push('Laptop')
    types.push('Cars')
    types.push('Toolbox')
    types.push('Small Electronics')
    types.push('Others')
    
    return types.map(type => ({
        value: type.toLowerCase(),
        label: type
    }))
}