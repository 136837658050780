import React from 'react'
import { Wrapper } from './wrapper';
import { Card, Typography } from '@mui/material';
import { Table } from '../../components/Table';

const headColumns = [
  { id: 'firstName', label: 'First name' },
  { id: 'borrowed', label: 'Borrowed date' },
  { id: 'status', label: 'Status' },
  { id: 'asset', label: 'Asset' },
  { id: 'serialNumber', label: 'Serial number' },
]

const history = [
  {
    __typename: 'history',
    firstName: 'Mihai',
    borrowed: '2020-01-01',
    asset: 'car',
    serialNumber: 'sckx03',
    status: 'not returned',
  },
  {
    __typename: 'history',
    firstName: 'Mihai',
    borrowed: '2020-01-01',
    asset: 'car',
    serialNumber: 'sckx02',
    status: 'not returned',
  },
  {
    __typename: 'history',
    firstName: 'Mihai',
    borrowed: '2020-01-01',
    asset: 'car',
    serialNumber: 'sckx03',
    status: 'returned',
  },
  {
    __typename: 'history',
    firstName: 'Mihai',
    borrowed: '2020-01-01',
    asset: 'Laptop',
    serialNumber: 'max1x04',
    status: 'returned',
  },
  {
    __typename: 'history',
    firstName: 'Mihai',
    borrowed: '2020-01-01',
    asset: 'Laptop',
    serialNumber: 'max1x04',
    status: 'not returned',
  },
]

export const EmployeeHistory = () => <Wrapper>
  <Typography variant="h3" gutterBottom sx={{paddingTop: '50px', paddingBottom: '50px' }}>
    Assets history for Mihai
  </Typography>
  <Card>
    <Table headColumns={headColumns} data={history} />
  </Card>
</Wrapper>