import { throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import gql from 'graphql-tag'

import { client } from "../../../start-up/client";
import { from } from "rxjs";

export const create = parameter => from(client.mutate({ mutation: createQuery, variables: { ...parameter }, fetchPolicy: 'no-cache' }))
    .pipe(map(response => response.data))
    .pipe(catchError(error => throwError(error.networkError.result)))

export const createQuery = gql`
    mutation createEmployee($email: String!, $employeeId: String!, $firstName: String!, $lastName: String!, $observations: String!, $phoneNumber: String!, $profilePhoto: String!) {
        createEmployee(email: $email, employeeId: $employeeId, firstName: $firstName, lastName: $lastName, observations: $observations, phoneNumber: $phoneNumber, profilePhoto: $profilePhoto) {
           id
        }
    }
`