import {useDispatch} from "react-redux";
import { actions } from "../slices/get"
import {useEffect} from "react";

export const useFetchEmployees = (filterValue) => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(actions.attempt(["Employee", filterValue]))
    },[filterValue])
}