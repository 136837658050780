import { useCallback } from "react";
import { useContext } from "pages/visitors/context";

export const useOnFilter = () => {
  const { setSearchValue } = useContext();

  return useCallback(
    (e) => {
      setSearchValue(e.target.value);
    },
    [setSearchValue]
  );
};
