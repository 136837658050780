import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { save } from 'helpers/local-storage'

export const useLoginHandle = () => {
  const navigate = useNavigate()

  return useCallback(values => {
    save('email', values.email)

    navigate('/search', { replace: true })
  }, [navigate])
}