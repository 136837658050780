import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useGetTitle } from './hooks/use-get-title';

export const Title = () => (
  <Grid item xs={12}>
    <Typography textAlign={'center'} variant="h2" fontWeight={'bold'} gutterBottom sx={{marginTop: '10px'}}>
      {useGetTitle()}
    </Typography>
  </Grid>
)