import React from 'react'
import { Box, Typography } from '@mui/material';

export const Observation = ({ observation }) => (
  <Typography sx={scrollableDiv} variant={'h5'}>
    {observation}
  </Typography>
)

const  scrollableDiv = {
  backgroundColor: '#f1f1f1',
  height: '150px',
  width: '80%',
  overflow: 'auto',
  margin: '20px',
  padding: '20px',
  wordBreak: 'break-word',
  textAlign: 'justify',
}

