import { useEffect } from 'react';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import { actions } from '../slices/get'

export const useFetchEmployee = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch(actions.attempt({id}))
    }, [dispatch, id])
}