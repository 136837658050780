import { combineReducers } from "@reduxjs/toolkit";

import { assetReducer } from '../pages/asset/slices/index'
import { assetsReducer} from "../pages/assets/slices";
import { visitorsReducer} from "../pages/visitors/slices";
import { departmentReducer} from "../pages/departments/slices";
import { userReducer} from "../pages/employees/slices";
import { observationReducer} from "../pages/observations/slices";
import { searchReducer } from "../pages/search/slices"
import { typesReducer } from "../features/types/slices";
import { employeeReducer } from '../pages/employee/slices';
import { entitiesReducer } from "../features/entities/slices";

export const rootReducer = combineReducers({
    assetReducer,
    assetsReducer,
    employeeReducer,
    visitorsReducer,
    departmentReducer,
    userReducer,
    observationReducer,
    searchReducer,
    typesReducer,
    entitiesReducer
})