import React from 'react'
import { Grid } from '@mui/material';
import { AssetsInside } from './components/AssetsInside';
import { AssetsOutside } from './components/AssetsOutside';
import { TodayVisitors } from './components/TodayVisitors';
import { Overdue } from './components/Overdue';

export const Header = () => <>
  <Grid item xs={12} sm={6} md={3}>
    <AssetsInside />
  </Grid>
  <Grid item xs={12} sm={6} md={3}>
    <AssetsOutside />
  </Grid>
  <Grid item xs={12} sm={6} md={3}>
    <Overdue />
  </Grid>
  <Grid item xs={12} sm={6} md={3}>
    <TodayVisitors />
  </Grid>
</>