import React, { useContext as useReactContext, useState } from 'react'

export const Context = React.createContext({})

export const useContext = () => useReactContext(Context)

export const useDefaultValues = () => {
  const [searchValue, setSearchValue] = useState('all')
  const [filterValue, setFilterValue] = useState('')
  const [firstSearch, setFirstSearch] = useState(true)

  return {
    searchValue,
    setSearchValue,
    filterValue,
    setFilterValue,
    firstSearch,
    setFirstSearch
  }
}
