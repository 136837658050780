import React from 'react'
import { Wrapper } from './wrapper';
import { Observation } from './observation';
import { Actions } from './actions';
import { Header } from './header';

export const Card = ({ item }) => <Wrapper>
  <Header item={item} />
  <Observation observation={item.observation} />
  <Actions />
</Wrapper>