import React, { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useContext } from './context';
import { useNavigate } from 'react-router-dom';

export const Actions = ({ employee }) => {
  const { setObservations, setIsModalOpen } = useContext()
  const [ obs, setObs ] = useState('')
  const navigate = useNavigate()
  
  const handleHistoryNavigate = () => navigate(`/employee/history/${employee.id}`)
  
  const handleAdd = () => {
    const newObs = {
      id: employee.id,
      personalId: employee.employeeId,
      firstName: employee.firstName,
      lastName: employee.lastName,
      createdDate: '25 May 2020',
      observation: obs
    }
    
    setObservations(prevState => [...prevState, newObs])
    
    setObs('')
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h6" gutterBottom sx={{paddingTop: '50px'}}>
        Observations:
      </Typography>
      <TextField
        sx={{width: '100%'}}
        fullWidth
        multiline
        value={obs}
        onChange={(e) => setObs(e.target.value)}
      />
      <Button disabled={!obs} variant="contained" color="primary" sx={buttonStyles} onClick={handleAdd}>
        Save observations
      </Button>
      <Button variant="contained" color="secondary" sx={buttonStyles} onClick={handleHistoryNavigate}>
        Open history assets 
      </Button>
    </Grid>
  );
}

const buttonStyles = {
  marginTop: '20px', 
  width: '100%',
  height: '50px',
}