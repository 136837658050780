import React from 'react'

import { Wrapper } from './wrapper';
import { Title } from './title';
import { Image } from './image';
import { Buttons } from './buttons';
import { Content } from './content';
import { useBehaviour } from './hooks/use-behaviour';
import { History } from './history';

const AssetPage = () => {
    useBehaviour()
    
    return <Wrapper>
      <Title />
      <Image />
      <Content />
      <Buttons />
      <History />
    </Wrapper>;
}

export default AssetPage;