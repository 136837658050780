import { useSelector } from 'react-redux';
import { useBehaviour } from './hooks/use-behaviour';
import { Wrapper } from './wrapper';
import { Title } from './title';
import { Image } from './image';
import { Information } from './information';
import { Observations } from './observations';
import { Actions } from './actions';

export const Employee = () => {
    useBehaviour()
    const employee = useSelector(selector);

    return <Wrapper>
        <Title />
        <Image />
        <Information employee={employee} />
        <Actions employee={employee} />
        <Observations />
    </Wrapper>
}

const selector = ({ employeeReducer }) => employeeReducer.get.data;
