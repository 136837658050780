import gql from 'graphql-tag';

export const getQuery = gql`
  query employees($employeeId: [Int]) {
    employees(ids: $employeeId) {
       id
      email
      employeeId
      firstName
      lastName
      observations
      phoneNumber
      profilePhoto
  }
}
`
