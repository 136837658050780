import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from '@mui/material'
import { Table as MuiTable } from 'components/Table'
import { useBuildSearch } from 'pages/search/hooks/useBuildSearch'
import { useContext } from 'pages/search/context'

const headColumns = [
  { id: 'type', label: 'Type'},
  { id: 'name', label: 'Name'},
  { id: 'responsible', label: 'Responsible'}
];

export const Table = () => {
  const { firstSearch } = useContext()
  const search = useSelector(searchSelector)
  const computedSearch = useBuildSearch(search)

  return (
    <>
      {
        !firstSearch && (
          <Card>
            <MuiTable headColumns={headColumns} data={computedSearch} clickable />
          </Card>
        )
      }
    </>
  )
}

const searchSelector = ({searchReducer}) => searchReducer.get.search;
