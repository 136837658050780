import React from 'react'
import { Wrapper } from 'pages/login/right-content/wrapper'
import { Form } from 'pages/login/right-content/form'
import { Title } from 'pages/login/right-content/title'
import { Select } from 'pages/login/right-content/select'

export const RightContent = () => (
  <Wrapper>
    <Title />
    <Select />
    <Form />
  </Wrapper>
)