import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Information = ({ employee }) => {
  const navigate = useNavigate()
  
  const handleSuperior = () => navigate(`/employee/3`)
  
  return <Grid xs={12} md={8}>
    <Typography variant="h4" gutterBottom>
      {employee?.firstName} {employee?.lastName}
    </Typography>
    <Typography variant="h6" gutterBottom sx={{marginTop: '40px'}}>
      Personal ID: {employee?.employeeId}
    </Typography>
    <Typography variant="h6" gutterBottom>
      Phone: {employee?.phoneNumber}
    </Typography>
    <Typography variant={'h6'} gutterBottom>
      Borrowed: Laptop - max x1 0075
    </Typography>
    <Typography variant="h6" gutterBottom>
      Email: {employee?.email}
    </Typography>
    <Typography variant="h6" sx={typoStyle} gutterBottom onClick={handleSuperior}>
      Superior: <span style={linkStyle}>Constantinescu Andrei</span>
    </Typography>
  </Grid>;
}

const linkStyle = {
  paddingTop: '50px', 
  textDecoration: 'underline', 
  color: 'blue',
  
  
}

const typoStyle = {
  '&:hover': {
    cursor: 'pointer'
  }
}