import React from 'react'
import { Stack } from '@mui/material'
import { Email } from 'pages/login/right-content/form/fields/email'
import { Password } from 'pages/login/right-content/form/fields/password'

export const Fields = () => (
  <Stack spacing={3}>
    <Email/>
    <Password/>
  </Stack>
)