import { ofType } from 'redux-observable';
import { actions } from '../slices/get';
import { catchError, mergeMap } from 'rxjs/operators';
import { repository } from '../repository';
import { of } from 'rxjs';

export const get = actions$ => actions$
  .pipe(ofType(actions.attempt))
  .pipe(mergeMap((execute)))

const execute = ({ payload }) => repository.get(payload)
  .pipe(mergeMap(success))
  .pipe(catchError(failure))

const success = payload => of(actions.success(payload))

const failure = payload => of(actions.failure(payload))