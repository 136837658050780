import React from 'react'
import { Box } from '@mui/material';
import { VerticalLine } from './vertical-line';

export const LeftStep = ({ item }) => (
  <>
    <Box sx={boxStyle(item.index, item.title, item.date)}/>
    <VerticalLine index={item.index} />
  </>
)

const boxStyle = (index, title, date) => ({
  width: '350px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  transition: '.5s',

  '&:after': {
    content: `"${index}"`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    border: '1px solid #79b873',
    height: '50px',
    width: '50px',
    backgroundColor: '#ebf8f2',
    cursor: 'pointer',
  },

  '&:before': {
    content: `"${title} - ${date}"`,
    position: 'absolute',
    top: '50%',
    right: `calc(50% + 35px)`,
    transform: 'translateY(-50%)',
    transition: '.5s',
  },

  '&:hover': {
    '&:after': {
      boxShadow: 'rgb(125,186,120, 0.7) 0px 5px 10px',
    }
  }
})
