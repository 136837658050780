import React from 'react'
import { Box, Card, CardHeader, Grid } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { useCreateOptions } from 'pages/dashboard/content/visitors/hooks/use-create-options'

const xAxisCategories = [
  '07/25/2022',
  '07/26/2022',
  '07/27/2022',
  '07/28/2022',
  '07/29/2022',
  '07/30/2022',
  '07/31/2022',
  '08/01/2022',
  '08/02/2022',
  '08/03/2022',
  '08/04/2022',
  '08/05/2022',
  '08/06/2022',
  '08/07/2022',
]

const CHART_DATA = [
  {
    name: 'Not expected',
    type: 'line',
    data: [44, 55, 41, 67, 22, 0, 0, 12, 32, 42, 50, 41, 0, 0]
  },
  {
    name: 'Not presented',
    type: 'line',
    data: [30, 25, 36, 30, 12, 0, 0, 35, 21, 12, 11, 4, 0, 0]
  }
];

export const Visitors = () => (
  <Grid item xs={12} md={6} lg={7}>
    <Card>
      <CardHeader title="Not expected or not presented visitors by days"/>
      <Box sx={{p: 3, pb: 1}} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={useCreateOptions(100, xAxisCategories)} height={364}/>
      </Box>
    </Card>
  </Grid>
)