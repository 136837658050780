import React from 'react'
import { Box } from '@mui/material';

export const Wrapper = ({ children }) => (
  <Box sx={boxStyle}>
    {children}
  </Box>
)

const boxStyle = {
  marginTop: '50px',
  width: '100%',
}