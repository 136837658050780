
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";
import React from "react";
import {useFormik} from "formik";
import Page from "../../components/Page";
import {Checkbox, Container} from "@mui/material";
import {FormikProvider} from "formik";
import {Form} from "formik";
import {Stack} from "@mui/material";
import {Typography} from "@mui/material";
import {TextField} from "@mui/material";
import {Select} from "@mui/material";
import {OutlinedInput} from "@mui/material";
import {Chip} from "@mui/material";
import {MenuItem} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {actions} from "../employees/slices/create"
import {useFormatTypes} from "../../features/types/hooks/useFormatTypes";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AddEmployee = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  const VisitorSchema = Yup.object().shape({
    email: Yup.string().required('Email name is required'),
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    employeeId: Yup.string().required('Employee Id is required'),
    phoneNumber: Yup.string().required('Phone number Id is required'),
    profilePhoto: Yup.string().required('Profile photo number Id is required'),
  });

  const formik = useFormik({
    initialValues: {
      observations: '',
      employeeId: '',
      firstName: '',
      lastName: '',
      profilePhoto: '',
      phoneNumber: '',
      email: '',
    },
    validationSchema: VisitorSchema,
    onSubmit: () => {
      const values = formik?.values
      
      dispatch(actions.attempt(values))
      navigate('/search')
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title='Gatha | Visitor'>
      <Container maxWidth={'md'}>
        <Typography variant='h2' sx={{ paddingBottom: "50px", paddingLeft: "150px"}}>Add an employee</Typography>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            
            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                First name*
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="First name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Stack>
            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                Last name*
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="Last name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>   
            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                Employee Id*
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="Employee Id"
                {...getFieldProps('employeeId')}
                error={Boolean(touched.employeeId && errors.employeeId)}
                helperText={touched.employeeId && errors.employeeId}
              />
            </Stack>
        
            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                Profile Photo
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="Profile Photo"
                {...getFieldProps('profilePhoto')}
                error={Boolean(touched.profilePhoto && errors.profilePhoto)}
                helperText={touched.profilePhoto && errors.profilePhoto} />
            </Stack>

            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                Phone number
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="Phone number"
                {...getFieldProps('phoneNumber')}
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                helperText={touched.phoneNumber && errors.phoneNumber} />
            </Stack>


            <Stack direction={'row'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'center', paddingLeft: '10px'}}>
                Email
              </Typography>
              <TextField
                sx={{width: '70%'}}
                label="Email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email} />
            </Stack>

            <Stack direction={'column'} spacing={3} justifyContent={'space-between'} sx={{paddingBottom: '20px'}}>
              <Typography variant='h5' sx={{alignSelf: 'left', paddingLeft: '10px'}}>
                Observations:
              </Typography>
              <TextField
                sx={{width: '100%'}}
                fullWidth
                multiline
                {...getFieldProps('observations')}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              Add asset
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  )
}
<h1>Hello</h1>