import React from 'react'
import { TextField } from '@mui/material'
import { useContext } from 'pages/login/context'

export const Email = () => {
  const { getFieldProps, touched, errors } = useContext()

  return (
    <TextField
      fullWidth
      autoComplete="username"
      type="email"
      label="Email address"
      {...getFieldProps('email')}
      error={Boolean(touched.email && errors.email)}
      helperText={touched.email && errors.email}
      disabled
    />

  )
}