import { useContext } from 'pages/login/context'
import { useEffect } from 'react'

const isManager = value => value === 'manager'

const isSecurityGuard = value => value === 'sg'

const isEmployee = value => value === 'employee'

export const useChangeFormValues = () => {
  const { selectValue, values, setFieldValue } = useContext()

  useEffect(() => {
    if(isManager(selectValue)) {
      setFieldValue('email', 'managerAccount@mail.com')
      setFieldValue('password', 'managerPassword')
    }
    else if(isSecurityGuard(selectValue)) {
      setFieldValue('email', 'securityGuardAccount@mail.com')
      setFieldValue('password', 'sgPassword')
    }
    else if(isEmployee(selectValue)) {
      setFieldValue('email', 'employeeAccount@mail.com')
      setFieldValue('password', 'employeePassword')
    }
  }, [selectValue, values])
}