import React from 'react'
import { Box, FormControl, InputLabel, MenuItem, Select as MuiSelect } from '@mui/material'
import { useContext } from 'pages/login/context'
import { useSelectHandle } from 'pages/login/right-content/hooks/use-select-handle'

export const Select = () => {
  const { selectValue } = useContext()

  return (
    <Box sx={{ margin: '5px 0 24px 0' }}>
      <FormControl fullWidth>
        <InputLabel id="account-select">Account type</InputLabel>
        <MuiSelect
          labelId="account-select"
          value={selectValue}
          label={'account-type'}
          onChange={useSelectHandle()}
        >
          <MenuItem value="sg">Security Guard</MenuItem>
          <MenuItem value="manager">Manager</MenuItem>
          <MenuItem value="employee">Employee</MenuItem>
        </MuiSelect>
      </FormControl>
    </Box>
  )
}
