import React from 'react'
import { Checkbox, FormControlLabel, Link, Stack } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useContext } from 'pages/login/context'

export const RememberMe = () => {
  const { getFieldProps, values } = useContext()

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <FormControlLabel
        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
        label="Remember me"
      />

      <Link component={RouterLink} variant="subtitle2" to="#">
        Forgot password?
      </Link>
    </Stack>
  )
}