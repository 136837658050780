import { TableRow } from "@mui/material";
import { TableCell } from "@mui/material";
import { TableBody } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {useCallback} from "react";

const useOnClick = () => {
    const navigate = useNavigate()
    
    return useCallback((id, path) => {
        navigate(`/${path}/${id}`)
    },[navigate])
}

export const Body = ({ page, rowsPerPage, filtered, emptyRows, bodyColumns, clickable, customClick }) => {
   
    const onClick = useOnClick()
    
    const customOrDefaultOnClick = customClick || onClick
    
    return (
        <TableBody>
            {filtered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) =>
                    (
                        <TableRow
                            hover
                            key={`${row.name}-${index}-${row.type}`}
                            tabIndex={-1}
                            sx={{ '&:hover': { cursor: 'pointer' } }}
                        >
                            {bodyColumns.map(column => {
                                const path = row.__typename.toLowerCase()
                                return (
                                    <TableCell onClick={() => clickable && customOrDefaultOnClick(row.id, path)}
                                               key={Math.random()}>{row[column.id]}</TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
            {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6}/>
                </TableRow>
            )}
        </TableBody>
    )
}