import { useFormatTypes } from "features/types/hooks/useFormatTypes";
import { useGetEntities } from "features/entities/hooks/useGetTypes";

export const useMakeSortOptions = () => {
    const types = useFormatTypes()
    const entities = useGetEntities()
    const allTypes = types.concat(entities)
    allTypes.push('All')
    
    return allTypes.map(type => ({
        value: type.toLowerCase(),
        label: type
    }))
}