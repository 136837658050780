import React from 'react'
import { MHidden } from 'components/@material-extend'
import { Card, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const LeftContent = () => (
  <MHidden width="mdDown">
    <SectionStyle>
      <Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>
        Hi, Welcome Back
      </Typography>
      <img src="/static/illustrations/illustration_login.png" alt="login"/>
    </SectionStyle>
  </MHidden>
)

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));
