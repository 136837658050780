import { useNavigateWithPayload } from 'helpers/hooks/use-navigate-with-payload'

export const useCreateChartEvents = () => {
  const navigate = useNavigateWithPayload('visitors')

  return ({
    events: {
      dataPointSelection: () => navigate(),
      dataPointMouseEnter: event => event.path[0].style.cursor = 'pointer'
    }
  })
}