import { useDispatch } from 'react-redux'
import { actions } from '../slices/get'
import { useEffect } from 'react'

export const useReset = () => {
  const dispatch = useDispatch();

  useEffect(() => () => {
      dispatch(actions.reset())
  },[dispatch])
}