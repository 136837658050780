import React from 'react'
import { Container } from '@mui/material'
import Page from 'components/Page'
import { Context, useDefaultValues } from 'pages/visitors/context'

export const Wrapper = ({ children }) => (
  <Context.Provider value={useDefaultValues()}>
    <Page title="Gatha | Visitors">
      <Container>
        {children}
      </Container>
    </Page>
  </Context.Provider>
)