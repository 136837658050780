import React from 'react'
import { useContext } from 'pages/login/context'
import { useChangeFormValues } from 'pages/login/right-content/form/hooks/use-change-form-values'

export const Wrapper = ({ children }) => {
  const { handleSubmit } = useContext()
  useChangeFormValues()

  return (
    <form onSubmit={handleSubmit}>
      {children}
    </form>
  )
}